import React, { ReactElement, FC, useMemo } from "react"
import { PageProps, navigate } from "gatsby"
import T from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Skeleton from "../components/Skeleton"
import { styled } from "@mui/material/styles"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { SectionReviewPageContext } from "../interfaces/PageContext"
import SEO from "../components/SEO"
import Link from "../components/Link"
import useQuestionAnswers from "../hooks/useQuestionAnswers"
import { useUser } from "../providers/UserProvider"
import { Question as QuestionType } from "../data/question-schema"
import { calculateQuestionStatuses, Status } from "../utility/calculateStatuses"
import formatQuestionOrStatement from "../utility/formatQuestionOrStatement"
import Question from "../components/questions"
import Button from "../components/design-system/Button"
import ButtonWrapper from "../components/ButtonWrapper"
import { visuallyHidden } from "@mui/utils"

type ProcessedSubsection = ProcessedPage[]

const ScreenReaderOnlyText = styled("span")(() => ({ ...visuallyHidden }))

interface ProcessedPage {
  id: string
  path: string
  title: string
  pageStatus?: Status
  pageIcon?: ReactElement
  visibleQuestions: QuestionType[]
}

const PageRow = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  paddingLeft: 0,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}))

const Icons = {
  Incomplete: styled(CircleOutlinedIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
  })),
  Complete: styled(CheckCircleIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
  })),
  Error: styled(WarningAmberOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.dark,
    position: "absolute",
    left: theme.spacing(-3.5),
  })),
}

/**
 * A component encapsulating the display of a question link.
 */
const QuestionLink = ({
  isLoading,
  isSubmitted,
  questionPagePath,
  questionPageStatus,
  questionPageTitle,
}: {
  // is the page still loading?
  isLoading: boolean
  // has the application been submitted?
  isSubmitted: boolean
  // the path to the question's page
  questionPagePath: string
  // the status of the question's page
  questionPageStatus: Status | undefined
  // page title
  questionPageTitle: string
}): ReactElement => {
  if (isSubmitted) {
    // the applicant should not be able to edit a question once the application is submitted
    return <></>
  }
  return (
    <Box sx={{ minWidth: "5em", textAlign: "right", pl: 1 }}>
      <T variant="body2">
        {isLoading ? (
          <Skeleton data-test-id="link-loading" />
        ) : (
          <Link to={questionPagePath} data-test-id="page-link">
            {questionPageStatus === Status.NotStarted
              ? "Start"
              : questionPageStatus === Status.Complete
              ? "Change"
              : "Continue"}{" "}
            <ScreenReaderOnlyText>({questionPageTitle})</ScreenReaderOnlyText>
          </Link>
        )}
      </T>
    </Box>
  )
}

export const SectionReview = ({
  section,
  questions,
}: SectionReviewPageContext): ReactElement => {
  // TODO: refactor to use `useQuestionStatuses` hook
  const answers = SectionReview.useQuestionAnswers()
  const user = SectionReview.useUser()
  const isLoading = answers === undefined || user.profile === undefined

  const statuses = useMemo(() => {
    return calculateQuestionStatuses(questions, answers, user.profile)
  }, [questions, answers, user])

  const processedSubsections: ProcessedSubsection[] = useMemo(() => {
    return section.subsections
      .filter(subsection => {
        const subsectionStatus = statuses?.subsections.get(subsection.id)
        if (subsectionStatus === "not-required") return false

        // Hide any conditional subsections when we haven't loaded some parts in.
        if (isLoading && subsection.conditions !== undefined) return false

        return true
      })
      .map(subsection =>
        subsection.pages
          .filter(page => {
            const pageStatus = statuses?.pages.get(page.id)

            if (pageStatus === "not-required") return false

            // Hide any conditional pages when we haven't loaded some parts in.
            if (isLoading && page.conditions !== undefined) return false

            return true
          })
          .map(page => {
            const pageStatus = statuses?.pages.get(page.id)

            const pageIcon =
              pageStatus === "complete" ? (
                <Icons.Complete />
              ) : (
                <Icons.Incomplete />
              )

            const visibleQuestions = page.questions
              .filter(question => {
                if (question.type !== "question") return false

                const questionStatus = statuses?.questions.get(question.id)
                if (questionStatus?.status === "not-required") return false

                // Hide any conditional questions when we haven't loaded some parts in.
                if (isLoading && question.conditions !== undefined) return false

                return true
              })
              // Evaluate formatted strings in question data
              .map(
                question =>
                  formatQuestionOrStatement(
                    question,
                    questions.options
                  ) as QuestionType
              )

            return {
              id: page.id,
              path: page.path,
              title: page.title,
              pageStatus,
              pageIcon,
              visibleQuestions,
            } as ProcessedPage
          })
      )
  }, [isLoading, statuses, section, questions])

  // If the whole section isn't visible, navigate away.
  if (statuses?.sections.get(section.id) === "not-required") {
    navigate("/application", { replace: true })
    return <></>
  }

  const nextSection =
    questions.sections[
      questions.sections.findIndex(
        thisSection => thisSection.id === section.id
      ) + 1
    ]

  return (
    <>
      <T variant="h3" component="h1">
        {section.title}
      </T>
      <T variant="h4" component="h2">
        Review your answers
      </T>
      <Box>
        {processedSubsections.map(subsection =>
          subsection.map(page => {
            return (
              <PageRow
                key={page.id}
                sx={{ display: "flex" }}
                data-test-page={page.id}
              >
                <Box sx={{ minWidth: "3rem" }} data-test-id="icon-wrapper">
                  {isLoading ? (
                    <Skeleton
                      variant="circular"
                      width="1.5rem"
                      height="1.5rem"
                    />
                  ) : (
                    page.pageIcon
                  )}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      {page.visibleQuestions.map(question => {
                        const questionStatus = statuses?.questions.get(
                          question.id
                        )
                        const unanswered =
                          !isLoading && questionStatus?.status === "not-started"
                        const hasErrors =
                          !unanswered && questionStatus?.errors != undefined

                        return (
                          <Question.Display
                            key={question.id}
                            isLoading={isLoading}
                            unanswered={unanswered}
                            hasErrors={hasErrors}
                            question={question}
                            answers={answers}
                            options={questions.options}
                          />
                        )
                      })}
                    </Box>
                    <QuestionLink
                      isLoading={isLoading}
                      isSubmitted={!!user.activity?.submissionTimestamp}
                      questionPagePath={page.path}
                      questionPageStatus={page.pageStatus}
                      questionPageTitle={page.title}
                    />
                  </Box>
                </Box>
              </PageRow>
            )
          })
        )}
      </Box>
      <ButtonWrapper
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "flex-start",
          rowGap: 1,
          columnGap: 2,
        }}
      >
        <Button
          role="link"
          color="secondary"
          onClick={() => navigate("/application")}
        >
          View my progress
        </Button>
        {nextSection && (
          <Button role="link" onClick={() => navigate(nextSection.path)}>
            Continue to next section
          </Button>
        )}
      </ButtonWrapper>
    </>
  )
}

SectionReview.useUser = useUser
SectionReview.useQuestionAnswers = useQuestionAnswers

const SectionReviewPage: FC<PageProps<never, SectionReviewPageContext>> = ({
  pageContext,
}) => {
  const { section, questions } = pageContext

  return (
    <>
      <SEO title={`${section.title}`} />
      <SectionReview section={section} questions={questions} />
    </>
  )
}

export default SectionReviewPage
